import * as z from "zod";

const requiredString = z.string().min(1);
const envSchema = z.object({
  REACT_APP_BASE_URL: requiredString,
  REACT_APP_PUBLISHABLE_KEY: requiredString,
  REACT_APP_PUBLIC_POSTHOG_KEY: z.string().optional(),
  REACT_APP_PUBLIC_POSTHOG_HOST: z.string().url().optional(),
});

export const env = envSchema.parse(process.env);
