import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { runFn as run, useApi } from "../api";
import { AppUser } from "./models";
import { queryKeys } from "./queryKeys";
import { useAuth, useUser } from "@clerk/clerk-react";

export interface UpsertUserPayload {
  authenticatorId: string;
  email: string;
  firstName: string;
  lastName: string;
  imageUrl?: string;
}
export async function upsertUser(payload: UpsertUserPayload): Promise<AppUser> {
  return await run<AppUser>({
    path: `/users`,
    method: "POST",
    body: payload,
  });
}

export function useMe() {
  const { run } = useApi();
  const { user } = useUser();
  const { isSignedIn, signOut } = useAuth();
  async function getMe(): Promise<AppUser | null> {
    if (!isSignedIn) return null;
    try {
      const user = await run<AppUser>({ method: "GET", path: "/users/me" }, false);
      return user;
    } catch {
      console.log(`invalid user. signing out.`);
      await signOut();
      return null;
    }
  }
  const query: UseQueryOptions<AppUser | null> = {
    queryKey: queryKeys.users.me(user?.id ?? "me"),
    queryFn: getMe,
    staleTime: user?.id ? 60000 : 0,
  };
  return useQuery(query);
}
