import { LoadingSpinner } from "../../components/Spinner";
import { Button } from "../../components/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import {
  useDeleteSession,
  useLinksBySession,
  useReorderGameNames,
  useSession,
} from "../../lib/data/session";
import { usePlayersBySession } from "../../lib/data/players";
import { PiShareBold } from "react-icons/pi";
import { FaChevronLeft, FaEllipsisH } from "react-icons/fa";
import { GoNumber } from "react-icons/go";
import { VscGlobe } from "react-icons/vsc";
import { usePaste } from "../../lib/utils/pasteboard";
import { MdDeleteOutline, MdOutlineDashboardCustomize } from "react-icons/md";
import { FaPeopleLine } from "react-icons/fa6";
import { RiEditLine } from "react-icons/ri";
import { useGenerateGame } from "../../lib/data/game";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { useState } from "react";
import { EditSessionPlayers } from "./EditSessionPlayers";
import { Link, useNavigate } from "react-router-dom";
import { useSessionPermissions } from "../../lib/permissions";
import { SessionBadges } from "../../components/Badges";
import { SessionInformation } from "./SessionInfo";
import { GrUserAdmin } from "react-icons/gr";
import { EditSessionHosts } from "./EditSessionHosts";
import { ConfirmationDialog } from "../../components/ConfirmationDialog";
import { EditSessionLinks } from "./EditSessionLinks";
import { LinkItem } from "../../components/LinkItem";
import { Divider } from "../../components/Divider";
import { EditSession } from "./EditSession";
import { BasketballSettingsBadges } from "../../components/sports/basketball/BasketballSettingsBadges";
import { useMenuOverrides } from "../../lib/utils/useMenuOverrides";

export function SessionPageHeader({ sessionId }: { sessionId: string }) {
  const { menuOverride, triggerOverride } = useMenuOverrides();
  const [modalEditSession, setModalEditSession] = useState<boolean>(false);
  const { data: session } = useSession(sessionId);
  const { data: players } = usePlayersBySession(sessionId);
  const { data: links = [] } = useLinksBySession(sessionId);
  const { mutateAsync: generateGameForSession } = useGenerateGame(sessionId);
  const { isCurrentUserSessionHost } = useSessionPermissions(sessionId);
  const canAddGame = isCurrentUserSessionHost && players && players.length >= 10;
  const canEditPlayers = isCurrentUserSessionHost;
  if (!session) {
    return <LoadingSpinner />;
  }
  const onGenerate = async () => {
    try {
      await generateGameForSession({
        sport: "basketball",
        awayTeamPlayersCount: 5,
        homeTeamPlayersCount: 5,
      });
    } catch (e) {
      console.error(`error ocurred when generating game`, e);
    }
  };

  const editSessionPlayersModal = (
    <DialogContent className="bg-white sm:max-w-xl">
      <DialogHeader>
        <DialogTitle>Session Players</DialogTitle>
      </DialogHeader>
      <EditSessionPlayers sessionId={sessionId} />
    </DialogContent>
  );

  const buttonsSection = (
    <div className="flex flex-col justify-center gap-3 p-3 px-2.5 empty:p-0 md:flex-row">
      {canEditPlayers && (
        <Dialog open={modalEditSession} onOpenChange={setModalEditSession}>
          {editSessionPlayersModal}
          <DialogTrigger asChild>
            <Button className="w-full" intent="yellow" label="edit players" />
          </DialogTrigger>
        </Dialog>
      )}
      <DropdownMenu {...menuOverride}>
        {canAddGame && (
          <DropdownMenuTrigger asChild disabled={!canAddGame} {...triggerOverride}>
            <Button disabled={!canAddGame} className="w-full" intent="green" label="add a game +" />
          </DropdownMenuTrigger>
        )}
        <DropdownMenuContent className="bg-white-custom">
          <Link to={`/session/${sessionId}/create`}>
            <DropdownMenuItem>
              <MdOutlineDashboardCustomize className="h-[20px] w-[36px] pb-[3px] pr-2" /> custom
              game
            </DropdownMenuItem>
          </Link>
          <DropdownMenuItem>
            <FaPeopleLine className="h-[20px] w-[36px] pb-[3px] pr-2" />
            <button onClick={onGenerate}>preloaded game</button>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );

  const linksSection = (
    <>
      <div className="flex flex-col justify-center gap-4 p-3 px-2.5 empty:p-0">
        <Divider />
        <div className="flex w-full flex-col items-center gap-3 md:flex-row md:justify-center">
          {links.map(link => (
            <LinkItem key={link.id} link={link} />
          ))}
        </div>
      </div>
    </>
  );

  const summarySection = (
    <div className="max-w-xl rounded-md md:max-w-full">
      <SessionInformation sessionId={sessionId} />
      <BasketballSettingsBadges sessionId={sessionId} />
      <SessionBadges sessionId={sessionId} />
      {session.description.length > 0 && (
        <div className="px-2.5 py-2 text-sm">
          <span className="font-medium">Notes: </span>
          {session.description}
        </div>
      )}
    </div>
  );

  return (
    <>
      <div className="flex w-full justify-center bg-green">
        <div className="min-w-sm max-auto flex max-w-screen-lg flex-1 items-center justify-between p-2.5">
          <Link to={`/dashboard`}>
            <FaChevronLeft aria-label="Back to sessions" size="24" fill="white" />
          </Link>
          <span className="header-banner text-white">SESSION</span>
          <SessionOptions sessionId={sessionId} />
        </div>
      </div>
      <div className="min-w-sm mx-auto max-w-screen-lg pb-6">
        {summarySection}
        {buttonsSection}
        {links.length > 0 && linksSection}
      </div>
    </>
  );
}

interface SessionOptionsProps {
  sessionId: string;
}
function SessionOptions({ sessionId }: SessionOptionsProps) {
  const navigate = useNavigate();
  const { menuOverride, triggerOverride } = useMenuOverrides();
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState<boolean>(false);
  const [modalEditSession, setModalEditSession] = useState<boolean>(false);
  const [modalEditHosts, setModalEditHosts] = useState<boolean>(false);
  const [modalEditLinks, setModalEditLinks] = useState<boolean>(false);
  const { isCurrentUserSessionHost, isCurrentUserSessionCreator } =
    useSessionPermissions(sessionId);
  const copyToClipboard = usePaste();
  const message = `Check out this game session on SomeDubs! Link here: ${window.location.href}`;
  const onShare = () => {
    copyToClipboard(message);
  };

  const { mutateAsync: reorder } = useReorderGameNames();
  const onReorder = async () => {
    await reorder(sessionId);
  };
  const { mutateAsync: deleteSession, isPending } = useDeleteSession();
  const onDelete = async () => {
    try {
      await deleteSession(sessionId);
      navigate("/dashboard");
    } catch (e) {
      console.error(`failed to delete session. ${e}`);
    }
  };

  const editSessionModal = (
    <Dialog open={modalEditSession} onOpenChange={setModalEditSession}>
      <DialogContent className="bg-white sm:max-w-xl">
        <DialogHeader>
          <DialogTitle>Edit Session</DialogTitle>
        </DialogHeader>
        <EditSession
          onCancel={() => setModalEditSession(false)}
          onComplete={() => setModalEditSession(false)}
          sessionId={sessionId}
        />
      </DialogContent>
    </Dialog>
  );

  const editSessionHostsModal = (
    <Dialog open={modalEditHosts} onOpenChange={setModalEditHosts}>
      <DialogContent className="bg-white sm:max-w-xl">
        <DialogHeader>
          <DialogTitle>Session Hosts</DialogTitle>
        </DialogHeader>
        <EditSessionHosts sessionId={sessionId} />
      </DialogContent>
    </Dialog>
  );

  const editSessionLinksModal = (
    <Dialog open={modalEditLinks} onOpenChange={setModalEditLinks}>
      <DialogContent className="bg-white sm:max-w-xl">
        <DialogHeader>
          <DialogTitle>Session Links</DialogTitle>
        </DialogHeader>
        <EditSessionLinks sessionId={sessionId} />
      </DialogContent>
    </Dialog>
  );

  const deleteDialog = (
    <ConfirmationDialog
      type="error"
      open={deleteConfirmationModal}
      onOpenChange={setDeleteConfirmationModal}
      action={onDelete}
      isLoading={isPending}
      cancelText="No. Not yet!"
      confirmText="Yes. Delete this session!"
    />
  );

  return (
    <DropdownMenu {...menuOverride}>
      {editSessionModal}
      {editSessionHostsModal}
      {editSessionLinksModal}
      {deleteDialog}
      <DropdownMenuTrigger {...triggerOverride} className="flex">
        <FaEllipsisH fill="white" size="30" />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="bg-white-custom">
        <DropdownMenuItem onClick={onShare} className="cursor-pointer">
          <PiShareBold className="h-[20px] w-[36px] pb-[3px] pr-2" /> Share Session
        </DropdownMenuItem>
        {isCurrentUserSessionHost && (
          <DropdownMenuItem onClick={() => setModalEditSession(true)} className="cursor-pointer">
            <RiEditLine className="h-[20px] w-[36px] pb-[3px] pr-2" /> Edit Session
          </DropdownMenuItem>
        )}
        {isCurrentUserSessionHost && (
          <DropdownMenuItem onClick={() => setModalEditHosts(true)} className="cursor-pointer">
            <GrUserAdmin className="h-[20px] w-[36px] pb-[3px] pr-2" /> Edit Hosts
          </DropdownMenuItem>
        )}
        {isCurrentUserSessionHost && (
          <DropdownMenuItem onClick={() => setModalEditLinks(true)} className="cursor-pointer">
            <VscGlobe className="h-[20px] w-[36px] pb-[3px] pr-2" /> Edit Links
          </DropdownMenuItem>
        )}
        {isCurrentUserSessionHost && (
          <DropdownMenuItem onClick={onReorder} className="cursor-pointer">
            <GoNumber className="h-[20px] w-[36px] pb-[3px] pr-2" /> Reorder Games
          </DropdownMenuItem>
        )}
        {isCurrentUserSessionCreator && (
          <DropdownMenuItem
            onClick={() => setDeleteConfirmationModal(true)}
            className="cursor-pointer"
          >
            <MdDeleteOutline className="h-[20px] w-[36px] pb-[3px] pr-2" /> Delete Session
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
