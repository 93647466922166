import { useGame } from "../data/game";
import { PlayerWithDetails } from "../data/models";
import { usePlayersByGame, usePlayersBySession } from "../data/players";
import { useSession } from "../data/session";
import { useMe } from "../data/user";

interface Hostable {
  creatorUserId: string;
  hosts: string[];
}

export function isHost(hostable: Hostable, userId: string): boolean {
  return hostable.hosts.includes(userId) || userId === hostable.creatorUserId;
}

export function useSessionPermissions(sessionId: string) {
  const { data: session, isSuccess: isSessionSuccess } = useSession(sessionId);
  const { data: me, isSuccess: isMeSuccess } = useMe();
  const isSuccess = isSessionSuccess && isMeSuccess;
  const isSessionHost = (userId: string) => (session ? isHost(session, userId) : false);
  const isCurrentUserSessionHost = me ? isSessionHost(me.id) : false;
  const isCurrentUserSessionCreator = me && session ? session.creatorUserId === me.id : false;

  return {
    isSuccess,
    isSessionHost,
    isCurrentUserSessionHost,
    isCurrentUserSessionCreator,
  };
}

// TODO: de-dupe?
export function useGamePermissions(gameId: string) {
  const { data: game, isSuccess: isGameSuccess } = useGame(gameId);
  const { data: me, isSuccess: isMeSuccess } = useMe();
  const isSuccess = isGameSuccess && isMeSuccess;
  const { isSessionHost } = useSessionPermissions(game?.collectionId ?? ""); // FIXME
  const isGameHost = (userId: string) =>
    game ? isHost(game, userId) || isSessionHost(userId) : false;
  const isCurrentUserGameHost = me ? isGameHost(me.id) || isSessionHost(me.id) : false;
  return {
    isSuccess,
    isGameHost,
    isCurrentUserGameHost,
  };
}

export function useIsPlayerInGame(userId: string | undefined, gameId: string) {
  const { data } = usePlayersByGame(gameId);
  if (!userId) return false;
  return (
    data?.homeTeam.find((player: PlayerWithDetails) => userId === player.user.id) ??
    data?.awayTeam.find((player: PlayerWithDetails) => userId === player.user.id)
  );
}

export function useIsPlayerInSession(userId: string | undefined, sessionId: string) {
  const { data } = usePlayersBySession(sessionId);
  if (!userId) return false;
  return data?.find((player: PlayerWithDetails) => userId === player.user.id);
}
