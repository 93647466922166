import { ReactNode, useEffect } from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Toaster } from "./ui/toaster";
import { useLocation } from "react-router-dom";
import { useAnalytics } from "../lib/analytics";

export function PageContainer({
  children,
  stickyHeader = true,
}: {
  children: ReactNode;
  stickyHeader?: boolean;
}) {
  const location = useLocation();
  const { logPageView } = useAnalytics();
  useEffect(() => {
    logPageView();
    // eslint-disable-next-line
  }, [location]);
  return (
    <div className="w-full">
      <Header stickyHeader={stickyHeader} />
      <div className="min-h-screen">{children}</div>
      <Toaster />
      <Footer />
    </div>
  );
}
